<template>
  <div>
    <screening :selected="selected" :states="states" @handleChange="handleSelected"/>
    <div class="course-list-wrapper">
      <course v-for="course in courseList" :course="course" :key="course.id"/>
    </div>
  </div>
</template>

<script>
import {listUserCourse} from "@/api/bm/course";
import course from '../components/course/index.vue'
import screening from '../components/screening/index.vue'

export default {
  components: {course,screening},
  data() {
    return {
      states: [
        {label: '全部', value: 'all'},
        {label: '待处理', value: 'pending'},
        {label: '已完结', value: 'finished'},
      ],
      selected: 'all',
      queryParams: {isDel: "N", courseType: '1'},
      courseList: []
    }
  },
  methods: {
    handleSelected(e) {
      this.selected = e
      switch (e) {
        case 'all':
          this.courseList = this.list
          break
        case 'pending':
          this.courseList = this.list.filter(item => {
            return ['0', '2', '4'].includes(item.status)
          })
          break
        case 'finished':
          this.courseList = this.list.filter(item => {
            return ['1', '3', '5'].includes(item.status)
          })
          break
      }
    },
    async getList() {
      const {rows} = await listUserCourse(this.queryParams)
      this.list = rows
      this.courseList = this.list
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="scss">
.course-list-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 32px;
}
</style>

